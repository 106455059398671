(function() {
'use strict';


	/* ------------------------------------------------------------
		画像など含めてページ読込み完了後に実行
	* ------------------------------------------------------------ */
	// ----------------------------------------
	// 要素の高さ揃え
	// ----------------------------------------
	function heightAlign() {
		$('.info__item-link').matchHeight();
	}
	$(window).on('load', function() {
		heightAlign();
	});
	

	/* ------------------------------------------------------------
		DOM構築後実行
	* ------------------------------------------------------------ */
	$(function() {

		/* 実行
		-------------------------------------------------- */
		$(function() {
			heroCarousel();
		});


		// ----------------------------------------
		// ヒーローカルーセル
		// ----------------------------------------
		function heroCarousel() {
			var $sliders = $('.hero__carousel'),
				$slider = $(".hero__carousel-container");
				$sliders.each(function() {
				var $this = $(this),
					$slideCount = $this.find('.slick-slide:not(.slick-cloned)').length;
				if ($slideCount <= 3) {
					
				} else {
				}
			});
			$sliders.each(function() {
				var $this = $(this);
				var slick = $this.find($slider).not('.slick-initialized').slick({
					infinite: true,
					centerMode: true,
					centerPadding: 0,
					dots: true,
					autoplay: true,
					autoplaySpeed: 3000,
					speed: 1000,
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					cssEase: 'ease',
					easing: 'easeInOutCubic',
					pauseOnFocus: false,
					pauseOnHover: false,
					pauseOnDotsHover: false,
					waitForAnimate: false
				});
			});
		}
		

	});

}).call(this);
